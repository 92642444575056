<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }} ({{ total }})
        <b-button
          @click="add()"
          class="is-primary is-pulled-right"
          icon-left="plus"
        >
          ເພີ່ມໃໝ່
        </b-button>
      </h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <div class="card" style="padding: 1em">
          <b-field>
            <b-input
              v-model="keyword"
              placeholder="ຊອກຫາ ຕາມ USER ID, ເບີໂທ, ຊື່"
              @keyup.native.enter="search"
            />
          </b-field>
        </div>
        <b-table
          default-sort="username"
          :loading="isLoading"
          :paginated="perPage < total"
          :backend-pagination="perPage < total"
          backend-sorting
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          :data="models"
          :current-page="currentPage"
          :pagination-rounded="true"
          @page-change="onPageChange"
          :total="total"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index + (currentPage - 1) * perPage + 1 }}
          </b-table-column>
          <b-table-column
            label="USER ID"
            field="username"
            sortable
            v-slot="props"
          >
            {{ props.row.username }}
          </b-table-column>
          <b-table-column label="ຊື່" field="firstname" sortable v-slot="props">
            {{ props.row.firstname }}
          </b-table-column>
          <b-table-column
            label="ນາມສະກຸນ"
            field="lastname"
            sortable
            v-slot="props"
          >
            {{ props.row.lastname }}
          </b-table-column>
          <b-table-column label="ເບີໂທ" field="tel" sortable v-slot="props">
            {{ props.row.tel }}
          </b-table-column>
          <b-table-column label="ສາຂາ" field="branch" sortable v-slot="props">
            {{ props.row.branch.name }}
          </b-table-column>
          <b-table-column label="ໜ້າທີ່" field="role" sortable v-slot="props">
            {{ props.row.role }}
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-warning"
                @click="edit(props.row)"
                icon-left="pencil"
              >
              </b-button>
              <b-button
                class="is-small is-grey"
                @click="editPassword(props.row)"
                icon-left="lock"
              >
              </b-button>
              <b-button
                class="is-small is-danger"
                @click="remove(props.row)"
                icon-left="trash-can"
              >
              </b-button>
            </div>
          </b-table-column>
          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>

    <div class="modal is-active is-large" v-if="isShowModal && model">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="USER ID" horizontal>
            <b-input v-model="model.username" placeholder="USER ID" required />
          </b-field>
          <b-field v-if="!model.id" label="ລະຫັດຜ່ານ" horizontal>
            <b-input
              v-model="model.password"
              type="password"
              placeholder="ລະຫັດຜ່ານ"
              required
            />
          </b-field>
          <b-field label="ຊື່ແທ້" horizontal>
            <b-input v-model="model.firstname" placeholder="ຊື່" required />
          </b-field>
          <b-field label="ນາມສະກຸນ" horizontal>
            <b-input v-model="model.lastname" placeholder="ນາມສະກຸນ" required />
          </b-field>
          <b-field label="ເບີໂທ" horizontal>
            <b-input v-model="model.tel" placeholder="ເບີໂທ" required />
          </b-field>
          <b-field label="ສາຂາ" horizontal>
            <b-select v-model="model.branch" placeholder="ສາຂາ" required>
              <option
                v-for="(branch, index) in branches"
                :key="index"
                :value="branch"
              >
                {{ branch.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="ໜ້າທີ່" class="has-check" horizontal>
            <radio-picker
              v-model="model.role"
              :options="{
                EMPLOYEE: 'ພະນັກງານສາຂາ',
                DELIVERYMAN: 'ພະນັກງານຂົນສົ່ງ',
                BUSDRIVER: 'ຄົນຂັບລົດເມ',
                BUSSERVER: 'ຄົນຂັບລົດເມ',
                ADMIN: 'ADMIN',
              }"
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="save()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="isShowModalPassword && model">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">ລະຫັດຜ່ານ</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModalPassword()"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="USER ID" horizontal>
            {{ model.username }}
          </b-field>
          <b-field label="ລະຫັດຜ່ານ" horizontal>
            <b-input
              v-model="model.password"
              type="password"
              placeholder="USER ID"
              required
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="save()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import RadioPicker from "@/components/RadioPicker";
export default {
  name: "Users",
  components: { CardComponent, EmptyTable, RadioPicker },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      total: null,
      title: "ພະນັກງານ",
      keyword: null,
      model: null,
      models: [],
      branches: [],
      isShowModal: false,
      isShowModalPassword: false,
      isLoading: false,
    };
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  mounted() {
    Axios.get("branches").then((res) => {
      this.branches = res;
    });
    this.search();
  },
  methods: {
    search() {
      if (this.keyword) {
        const offset = (this.currentPage - 1) * this.perPage;
        const url = `users/search/${this.keyword}/${this.perPage}/${offset}`;
        this.isLoading = true;
        Axios.get(url).then((r) => {
          this.models = r.models;
          this.total = r.total;
        });
      } else {
        this.get();
      }
    },
    get() {
      const offset = (this.currentPage - 1) * this.perPage;
      Axios.get(`users/gets/${this.perPage}/${offset}`).then((r) => {
        this.models = r.models;
        this.total = r.total;
      });
    },
    add() {
      this.isShowModal = true;
      this.model = {
        sex: "female",
        role: "EMPLOYEE",
      };
    },
    edit(item) {
      this.model = item;
      this.model.branch = this._.find(
        this.branches,
        (b) => b.id === item.branch_id
      );
      console.log(this.model.branch);
      this.isShowModal = true;
    },
    editPassword(item) {
      this.model = item;
      this.model.password = null;
      this.isShowModalPassword = true;
    },
    save() {
      let req;
      this.model.user_id = this.user.id;
      this.model.password = this.sha512(this.model.password);
      console.log(this.model);

      console.log("here", this.model.username.match(/^[0-9a-zA-Z]{3,}$/g));

      //not allow alpha numberic
      if (!this.model.username.match(/^[0-9a-zA-Z]{3,}$/g)) {
        this.$buefy.snackbar.open({
          type: "is-warning",
          message: `USER ID ຕ້ອງເປັນໂຕອັກສອນອັງກິດ ແລະ ໂຕເລກເທົ່ານັ້ນ ແລະ ຍາວ 3 ຕົວອັກສອນຂຶ້ນໄປ`,
          queue: true,
        });
        return;
      }

      if (!this.model.id) {
        req = Axios.put("users", this.model);
      } else {
        req = Axios.post("users", this.model);
      }
      req.then((res) => {
        console.log(res);
        this.closeModal();
        this.closeModalPassword();
        this.search();
      });
    },
    remove(item) {
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນການລຶບຂ້ໍມູນ",
        message: "ທ່ານຕ້ອງການ <b>ລຶບ</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ລຶບ",
        cancelText: "ບໍ່",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          Axios.delete(`users/${item.id}`).then((r) => {
            console.log(r);
            this.search();
          });
        },
      });
    },
    closeModal() {
      this.isShowModal = false;
    },
    closeModalPassword() {
      this.isShowModalPassword = false;
    },
    onPageChange(page) {
      this.currentPage = page;
      this.search();
    },
  },
};
</script>